.App {
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
}

.App-link {
  color: #61dafb;
}

* > body {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: white !important;
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000000;
  color: white !important;
  padding: 8px;
  z-index: 100;
}

.skip-link:focus {
  top: 0;
}

.MuiToolbar-regular {
  min-height: 2rem !important;
}
